import { Injectable } from '@angular/core';
import { kerberosConfig } from '../../environments/environment';

@Injectable()
export class ApiService {

  private root : string = kerberosConfig.apiUrl;
  private newRoot : string = kerberosConfig.newApiUrl;

  constructor() {}

  // POST - get current user.
  // Requires a JWT token.
  public login() {
    return this.newRoot + "/login";
  }

  // POST - register user.
  // Requires a JWT token.
  public register() {
    return this.newRoot + "/register";
  }

  // POST - activate user.
  // Requires a JWT token.
  public activate() {
    return this.newRoot + "/activate";
  }

  // POST - forgot user account.
  // Requires a JWT token.
  public forgot() {
    return this.newRoot + "/forgot";
  }

  // GET - get all accounts.
  // Requires a JWT token.
  public getAccounts() {
    return this.newRoot + "/user/accounts";
  }

  // GET - find an account.
  // Requires a JWT token.
  public getAccount(id) {
    return this.newRoot + "/user/accounts/" + id;
  }

  // POST - add a new account.
  // Requires a JWT token.
  public addAccount() {
    return this.newRoot + "/user/accounts";
  }

  // PUT - edit an account.
  // Requires a JWT token.
  public editAccount(id) {
    return this.newRoot + "/user/accounts/" + id;
  }

  // DELETE - delete an account.
  // Requires a JWT token.
  public deleteAccount(id) {
    return this.newRoot + "/user/accounts/" + id;
  }

  // GET - get all roles.
  // Requires a JWT token.
  public getRoles() {
    return this.newRoot + "/roles";
  }

  // GET - find a role.
  // Requires a JWT token.
  public getRole(id) {
    return this.newRoot + "/roles/" + id;
  }

  // POST - add a new role.
  // Requires a JWT token.
  public addRole() {
    return this.newRoot + "/roles";
  }

  // PUT - edit a role.
  // Requires a JWT token.
  public editRole(id) {
    return this.newRoot + "/roles/" + id;
  }

  // DELETE - delete an role.
  // Requires a JWT token.
  public deleteRole(id) {
    return this.newRoot + "/roles/" + id;
  }

  // Build for WDC!
  // GET - get all recordings.
  // Requires a JWT token.
  public getRecordings() {
    return this.newRoot + "/recent-recordings";
  }

  // GET - get all sites.
  // Requires a JWT token.
  public getSites() {
    return this.newRoot + "/sites";
  }

  // GET - find a site.
  // Requires a JWT token.
  public getSite(id) {
    return this.newRoot + "/sites/" + id;
  }

  // GET - find all devices of a site.
  // Requires a JWT token.
  public getDevicesFromSite(id) {
    return this.newRoot + "/sites/" + id + '/devices';
  }

  // POST - add a new site.
  // Requires a JWT token.
  public addSite() {
    return this.newRoot + "/sites";
  }

  // PUT - edit a site.
  // Requires a JWT token.
  public editSite(id) {
    return this.newRoot + "/sites/" + id;
  }

  // DELETE - delete a site.
  // Requires a JWT token.
  public deleteSite(id) {
    return this.newRoot + "/sites/" + id;
  }

  // PUT - assign devices to a site.
  // Requires a JWT token.
  public assignDevices(id) {
    return this.newRoot + "/sites/" + id + "/devices";
  }

  // GET - get all groups.
  // Requires a JWT token.
  public getGroups() {
    return this.newRoot + "/groups";
  }

  // GET - find a group.
  // Requires a JWT token.
  public getGroup(id) {
    return this.newRoot + "/groups/" + id;
  }

  // GET - find all devices of a group.
  // Requires a JWT token.
  public getDevicesFromGroup(id) {
    return this.newRoot + "/groups/" + id + '/devices';
  }

  // POST - add a new site.
  // Requires a JWT token.
  public addGroup() {
    return this.newRoot + "/groups";
  }

  // PUT - edit a site.
  // Requires a JWT token.
  public editGroup(id) {
    return this.newRoot + "/groups/" + id;
  }

  // DELETE - delete a site.
  // Requires a JWT token.
  public deleteGroup(id) {
    return this.newRoot + "/groups/" + id;
  }

  // PUT - assign devices to a site.
  // Requires a JWT token.
  public assignDevicesToGroup(id) {
    return this.newRoot + "/groups/" + id + "/devices";
  }

  // GET - get all tasks.
  // Requires a JWT token.
  public getTasks() {
    return this.newRoot + "/tasks";
  }

  // POST - get all filtered tasks.
  // Requires a JWT token.
  public getFilteredTasks() {
    return this.newRoot + "/tasks/filter";
  }

  // GET - get tasks statistics.
  // Requires a JWT token.
  public getTasksStatistics() {
    return this.newRoot + "/tasks/statistics";
  }

  // POST - add a new task.
  // Requires a JWT token.
  public addTask() {
    return this.newRoot + "/tasks";
  }

  // PUT - edit a task.
  // Requires a JWT token.
  public editTask(id) {
    return this.newRoot + "/tasks/" + id;
  }

  // DELETE - delete a task.
  // Requires a JWT token.
  public deleteTask(id) {
    return this.newRoot + "/tasks/" + id;
  }

  // GET - get all notifications/alerts for watchlist.
  // Requires a JWT token.
  public getNotifications() {
    return this.newRoot + "/notifications";
  }

  // POST - get all notification tasks.
  // Requires a JWT token.
  public getFilteredNotifications() {
    return this.newRoot + "/notifications/filter";
  }

  // GET - get notification statistics.
  // Requires a JWT token.
  public getNotificationsStatistics() {
    return this.newRoot + "/notifications/statistics";
  }

   // PUT - edit a notification/alert.
  // Requires a JWT token.
  public editNotification(id) {
    return this.newRoot + "/notifications/" + id;
  }

  // GET - Mark all events from matchlist as read.
  // Requires a JWT token.
  public readAllWatchlist() {
    return this.newRoot + "/notifications/markall";
  }

  public semanticMediaFilter() {
    return this.newRoot + "/semantic/mediafilter";
  }

  // GET - get all devices from a user.
  // Requires a JWT token.
  public getDevices() {
    return this.newRoot + "/devices";
  }

  // GET - get all the media from devices of a user.
  // Requires a JWT token.
  public getMediaDevices() {
    return this.newRoot + "/devices/media";
  }

  // GET - get all devices from a user.
  // Requires a JWT token.
  public getDeviceStatistics() {
    return this.newRoot + "/devices/meta/statistics";
  }

  // GET - find a device.
  // Requires a JWT token.
  public findDevice(device) {
    return this.newRoot + "/devices/" + device;
  }

  // PUT - add a property to a device
  // Requires a JWT token.
  public updateDevice(device) {
    return this.newRoot + "/devices/" + device;
  }

  // POST - add a preset to a device
  // Requires a JWT token.
  public addPreset(device) {
    return this.newRoot + "/devices/" + device + "/presets";
  }

  // DELETE - delete a preset from a device
  // Requires a JWT token.
  public deletePreset(device, preset) {
    return this.newRoot + "/devices/" + device + "/presets/" + preset;
  }

  // DELETE - remove a device
  // Requires a JWT token.
  public deleteDevice(device) {
    return this.newRoot + "/devices/" + device;
  }

  // POST - mute a device
  // Requires a JWT token.
  public muteDevice(device) {
    return this.newRoot + "/devices/mute/" + device;
  }

  // GET - get all notifications from a user.
  // Requires a JWT token.
  public getUserNotifications() {
    return this.newRoot + "/user/notifications";
  }

  // POST - mark notification as read.
  // Requires a JWT token.
  public markReadUserNotification() {
    return this.newRoot + "/user/notifications/read";
  }

  // POST - remove all notification.
  // Requires a JWT token.
  public markReadAllUserNotification() {
    return this.newRoot + "/user/notifications/remove-all";
  }

  // POST - send feedback to slack channels
  // Requires a JWT token.
  public sendFeedback() {
    return this.newRoot + "/feedback/slack";
  }

  // GET - Get user profile.
  // Requires a JWT token.
  public getProfile() {
    return this.newRoot + "/user/profile";
  }

  // PUT - Update user profile.
  // Requires a JWT token.
  public updateProfile() {
    return this.newRoot + "/user/profile";
  }

  // PUT - Update user settings.
  // Requires a JWT token.
  public updateSettings() {
    return this.newRoot + "/user/settings";
  }

  // PUT - Update user credentials.
  // Requires a JWT token.
  public updateCredentials() {
    return this.newRoot + "/user/credentials";
  }

  // GET - get all releases
  // Requires a JWT token.
  public getReleases() {
    return this.newRoot + "/release_notes";
  }

  // POST - get analysis from end user
  // Requires a JWT token.
  public getStatistics() {
    return this.newRoot + "/analytics";
  }

  // GET - get ios from end user
  // Requires a JWT token.
  public getIOs() {
    return this.newRoot + "/ios";
  }

  // POST - get all filtered ios from end user
  // Requires a JWT token.
  public filterIOs() {
    return this.newRoot + "/ios/filter";
  }

  // DELETE - remove a ios from end user
  // Requires a JWT token.
  public deleteIO(id) {
    return this.newRoot + "/ios/" + id;
  }

  // GET - get a specific analysis
  // Requires a JWT token.
  public getAnalysis(id: string) {
    return this.newRoot + "/analysis/" + id;
  }

  // POST - get a HLS stream from a device.
  // Requires a JWT token.
  public getHLS() {
    return this.newRoot + "/storage/hls";
  }

  // POST - get all media from a user with filter.
  // Requires a JWT token.
  public getMedia() {
    return this.newRoot + "/media";
  }

  // GET - find media with an id.
  // Requires an id and JWT token.
  public findMedia(id) {
    return this.newRoot + "/media/" + id;
  }

  // POST - share a media with external people.
  // Requires a JWT token.
  public shareMedia() {
    return this.newRoot + "/media/share";
  }

  // GET - get all days from a user.
  // Requires a JWT token.
  public getDays() {
    return this.newRoot + "/media/days";
  }

  // GET - get all hours of a specific day.
  // Requires a day and a JWT token.
  public getHours(day) {
    return this.newRoot + "/media/" + day + "/hours";
  }

  // POST - star of unstar a sequence by its id.
  // Requires an id and JWT token.
  public starSequence(id) {
    return this.newRoot + "/media/" + id + "/star";
  }

  // DELETE - remove a sequence by its id.
  // Requires an id and JWT token.
  public deleteSequence(id) {
    return this.newRoot + "/media/" + id;
  }

  // GET - get the kerberos storage credentials of the user.
  // Requires a JWT token.
  public getStorage() {
    return this.newRoot + "/storage";
  }

  // POST - update the kerberos storage credentials of the user.
  // Requires a JWT token.
  public updateStorage() {
    return this.newRoot + "/storage";
  }

  // POST - verify the kerberos storage credentials of the user.
  // Requires a JWT token.
  public verifyStorage() {
    return this.newRoot + "/storage/verify";
  }

  // GET - Get all notification settings.
  // Requires a JWT token.
  public getNotificationSettings() {
    return this.newRoot + "/notificationsettings";
  }

  // PUT - Update notification settings.
  // Requires a JWT token.
  public updateNotificationSettings() {
    return this.newRoot + "/notificationsettings";
  }

  // GET - Get all custom alerts.
  // Requires a JWT token.
  public getCustomAlerts() {
    return this.newRoot + "/alerts";
  }

  // POST - Create a custom alerts.
  // Requires a JWT token.
  public createCustomAlert() {
    return this.newRoot + "/alerts";
  }

  // UPDATE - Update a custom alerts.
  // Requires a JWT token.
  public updateCustomAlert(id: string) {
    return this.newRoot + "/alerts/" + id;
  }

  // DELETE - Update a custom alerts.
  // Requires a JWT token.
  public deleteCustomAlert(id: string) {
    return this.newRoot + "/alerts/" + id;
  }

  // GET - Get all notification channels.
  // Requires a JWT token.
  public getChannels() {
    return this.newRoot + "/channels";
  }

  // PUT - Update notification channels.
  // Requires a JWT token.
  public updateChannels() {
    return this.newRoot + "/channels";
  }

  // GET - Validate a coupon.
  // Requires a JWT token.
  public validateCoupon() {
    return this.newRoot + "/subscription/coupon";
  }

  // GET - get the plan of the user.
  // Requires a JWT token.
  public getPlan() {
    return this.newRoot + "/subscription/plan";
  }

  // GET - get the activity of the user.
  // Requires a JWT token.
  public getActivity() {
    return this.newRoot + "/subscription/activity";
  }

  // GET - get the plan settings.
  // Requires a JWT token.
  public getSettings() {
    return this.newRoot + "/subscription/settings";
  }

  // GET - get the live stream properties.
  // Requires a JWT token.
  public getLivestream() {
    return this.newRoot + "/livestream";
  }

  // GET - update the live stream properties.
  // Requires a JWT token.
  public updateSpeech() {
    return this.newRoot + "/livestream";
  }

  // POST - Disable MFA.
  // Requires a JWT token.
  public disableMFA() {
    return this.newRoot + "/profile/2fa/disable";
  }

  // GET - Get qr code.
  // Requires a JWT token.
  public getQRCode() {
    return this.newRoot + "/profile/2fa";
  }

  // POST - Validate qr code.
  // Requires a JWT token.
  public validateQRCode() {
    return this.newRoot + "/profile/2fa";
  }

  // POST - Set encryption.
  // Requires a JWT token.
  public setEncryption() {
    return this.newRoot + "/profile/encryption";
  }

  // POST - Test channel.
  // Requires a JWT token.
  public testChannel() {
    return this.newRoot + "/channels/test";
  }

  // GET - get dashboard kpi's.
  // Requires a JWT token.
  public getDashboard() {
    return this.newRoot + "/dashboard";
  }

  // POST - add customer
  // Requires a JWT token.
  public addCustomer() {
    return this.newRoot + "/subscription/customer";
  }


  // POST - add payment method to customer
  // Requires a JWT token.
  public addPaymentMethod() {
    return this.newRoot + "/subscription/payment_method";
  }

  // PUT - save a new card
  // Requires a JWT token.
  public updateCard() {
    return this.newRoot + "/profile/card";
  }

  // DELETE - delete a card
  // Requires a JWT token.
  public deleteCard() {
    return this.newRoot + "/profile/card";
  }

  // POST - purchase a plan
  // Requires a JWT token.
  public purchasePlan() {
    return this.newRoot + "/subscription/plan";
  }

  // DELETE - remove current plan
  // Requires a JWT token.
  public deletePlan() {
    return this.newRoot + "/subscription/plan/delete";
  }

  // POST - cancel current plan
  // Requires a JWT token.
  public cancelPlan() {
    return this.newRoot + "/subscription/plan/cancel";
  }

  // POST - resume current plan
  // Requires a JWT token.
  public resumePlan() {
    return this.newRoot + "/subscription/plan/resume";
  }

  // GET - get all invoices from a user.
  // Requires a JWT token.
  public getInvoices() {
    return this.newRoot + "/profile/invoices";
  }





  // LOWER PRIORITY!!
  // TODO TODO TODO

  // @TODO!
  // POST - change region
  // Requires a JWT token.
  public changeRegion() {
    return this.root + "/subscription/region";
  }

  // TODO MEDIA features
  // POST - add a label
  // Requires an id and JWT token.
  public addLabel(id, media) {
    return this.root + "/media/" + id + "/" + media + "/label";
  }

  // DELETE - remove a label
  // Requires an id and JWT token.
  public removeLabel(id, media, label) {
    return this.root + "/media/" + id + "/" + media + "/label/" + label;
  }

  // POST - update description
  // Requires an id and JWT token.
  public updateDescription(id, media) {
    return this.root + "/media/" + id + "/" + media + "/description";
  }

  // TODO TODO TODO
  // TODO VAULT FEATURE (MERGED WITH TASKS)

  // GET - get vault from user.
  // Requires an id and JWT token.
  public getVault() {
    return this.root + "/vault";
  }

  // GET - find vault with an id.
  // Requires an id and JWT token.
  public findVault(id, media) {
    return this.root + "/vault/" + id + "/" + media;
  }

  // PUT - add to vault.
  // Requires an id and JWT token.
  public addToVault(id, media) {
    return this.root + "/media/" + id + "/" + media + "/vault";
  }

  // DELETE - remove from vault.
  // Requires an id and JWT token.
  public removeFromVault(id, media) {
    return this.root + "/media/" + id + "/" + media + "/vault";
  }


  // TODO TODO TODO
  // TODO COUNTING FEATURE!

  // GET - get the regions of the counting service.
  // Requires a JWT token.
  public getRegions() {
    return this.root + "/counting/regions";
  }

  // POST - add a new region to the counting service.
  // Requires a JWT token.
  public addRegion() {
    return this.root + "/counting/regions";
  }

  // PUT - update a region to the counting service.
  // Requires a JWT token.
  public updateRegion() {
    return this.root + "/counting/regions";
  }

  // DELETE - delete a region to the counting service.
  // Requires a JWT token.
  public deleteRegion(device, id) {
    return this.root + "/counting/regions/" + device + "/" + id;
  }

  // GET - get all the counting analytics
  // Requires a day and a JWT token.
  public getCountingAnalytics() {
    return this.root + "/counting/analytics";
  }
}
