import {Component, OnInit, OnDestroy, Input, Inject} from '@angular/core';
import {IMqttMessage, MqttService as MqttService2} from "ngx-mqtt";
import {
  MqttService,
} from '../../../../mqtt.service';
import * as fromDevices from '../../../../../home/devices/store';
import {kerberosConfig} from "../../../../../../environments/environment";
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

@Component({
  selector: "MqttStream",
  templateUrl: './mqttstream.component.html',
  styleUrls: ['./mqttstream.component.scss'],
})
export class MqttStream implements OnInit, OnDestroy  {

  @Input() deviceKey: string;
  @Input() cloudKey: string;
  @Input() changeStatus: any;
  @Input() encrypted: boolean = false;

  public status: string = 'pending';
  public livestream;
  public liveStreamObservable;
  public liveLegacyStreamObservable;
  public pollingObservables: Array<any> = [];
  public livestreamStarted: boolean = false;
  public timer: any;
  public timerSubscription: any = null;

  public eventsSubscription: Subscription;
  public events$ = this.store.select(fromDevices.getEvents);
  public events: any;

  constructor(
    private store: Store<fromDevices.State>,
    @Inject('mqttNew') private _mqttService: MqttService2,
    @Inject('mqttLegacy') private _mqttLegacyService: MqttService2,
    private mqttService: MqttService) {}

  ngOnInit(){
    this.updateStatus('pending');
    const cloudKey = this.cloudKey;
    const deviceKey = this.deviceKey;

    this.publishRequestMQTTLiveStream(cloudKey, deviceKey);
    this.subscribeToMQTTLiveStream(cloudKey, deviceKey);
  }

  updateStatus(status) {
    this.status = status;
    if(this.changeStatus){
      this.changeStatus(status);
    }
  }

  subscribeToMQTTLiveStream(cloudKey, deviceKey) {

    this.eventsSubscription = this.events$.subscribe((e) => {
      // Filter on the id of the device
      const events = e.filter((event) => event.key === this.deviceKey);
      if(events && events.length > 0) {
        const event = events[0];
        if (event.action === 'receive-sd-stream') {
          const image = event.value.image.toString();
          this.livestream = image;
          this.updateStatus('started');
        }
      }
    });
          
    
    // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    // TODO GET RID OF IT, but we can't do it now because we have to support old agents
    // Legacy
    if(kerberosConfig.mqttLegacyServer != "") {
      this.liveLegacyStreamObservable = this._mqttLegacyService.observe(`kerberos/${cloudKey}/device/${deviceKey}/live`).subscribe((message: IMqttMessage) => {
        const topic = message.topic.split("/")[3];
        if(topic===deviceKey) {
          this.livestream = message.payload.toString();
        }
        this.updateStatus('started');
      });
    }

    // New MQTT
    this.liveStreamObservable = this._mqttService.observe(`kerberos/${cloudKey}/device/${deviceKey}/live`).subscribe((message: IMqttMessage) => {
      const topic = message.topic.split("/")[3];
      if(topic===deviceKey) {
        this.livestream = message.payload.toString();
      }
      this.updateStatus('started');
    });
  }

  publishRequestMQTTLiveStream(cloudKey, deviceKey) {
    const interval = 2000;
    this.timerSubscription = setInterval(() => {
      
      // New Method single observable
      // We should get rid of the legacy methods as mentioned below.
      const topic = "kerberos/agent/" + this.cloudKey;
      const payload = {
        action: "request-sd-stream",
        device_id: this.deviceKey,
        value: {
          timestamp: Math.floor(Date.now() / 1000),
        }
      };
      this.mqttService.publish(topic, payload, this.encrypted)

      // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
      // TODO GET RID OF IT, but we can't do it now because we have to support old agents
      // Legacy
      if(kerberosConfig.mqttLegacyServer != "") {
        this._mqttLegacyService.unsafePublish('kerberos/' + cloudKey + '/device/' + deviceKey + '/request-live', Math.random().toString(), {qos: 0});
      }
      this._mqttService.unsafePublish('kerberos/' + cloudKey + '/device/' + deviceKey + '/request-live', Math.random().toString(), {qos: 0});
    }, interval);
  }

  ngOnDestroy(){
    clearInterval(this.timerSubscription)
    if(this.liveStreamObservable) {
      this.liveStreamObservable.unsubscribe();
    }
    if(this.liveLegacyStreamObservable) {
      this.liveLegacyStreamObservable.unsubscribe();
    }
    if(this.eventsSubscription) {
      this.eventsSubscription.unsubscribe();
    }
  }
}
