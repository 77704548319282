import {Component, OnInit, Input, SimpleChanges, ViewChild} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import * as fromLivestream from '../../../home/livestream/store';
import * as fromDevices from '../../../home/devices/store';

@Component({
  selector: 'StreamComponent',
  templateUrl: './stream.component.html',
  styleUrls: ['./stream.component.scss']
})
export class StreamComponent {

  @ViewChild('webrtc', { static: false }) webrtc: any;
  @ViewChild('mqtt', { static: false }) mqtt: any;

  @Input() deviceKey: any;
  @Input() cloudKey: any;
  @Input() active: boolean = true;
  @Input() cameraConnected: string = "false";
  @Input() hasBackChannel: string = "false";
  @Input() hdEnabled: boolean = false;
  @Input() mobile: boolean = false;
  @Input() fullscreen: boolean = true;
  @Input() hideButton: boolean = false;
  @Input() encrypted: boolean = false;  
  @Input() onvif: string = "";
  @Input() onvifZoom: string = "";
  @Input() onvifPanTilt: string = "";
  @Input() presets: Array<any> = [];
  @Input() onboardPresets: Array<any> = [];
  @Input() onboardIOs: Array<any> = [];
  @Input() permissions: any = null;
  @Input() devicePermissions: any = null;

  public status: string = "pending";
  public mode: string = "SD";
  public isFullscreen: boolean = false;
  public isMuted: boolean = false;

  public livestreamSubscription;
  public livestream$ = this.store.select(fromLivestream.getLivestream);
  public livestreamProperties: any;

  constructor(private title: Title,
              private store: Store<fromDevices.State>) {
    this.changeMode = this.changeMode.bind(this);
    this.changeStatus = this.changeStatus.bind(this);
    this.changeFullscreen = this.changeFullscreen.bind(this);
  }
  
  changeStatus(status) {
    this.status = status;
  }

  muteUnmute() {
    this.isMuted = !this.isMuted;
    this.webrtc.mute(this.isMuted);
  }

  changeMode(mode) {
    if (mode == 'HD' && !this.hdEnabled) {
      // Nothing to do here sherlock!
    } else {
      this.mode = mode;
    }
  }

  changeFullscreen(){
    this.isFullscreen = !this.isFullscreen;
  }

  ngAfterViewInit() {
    this.muteUnmute = this.muteUnmute.bind(this);
  }

  public ngOnDestroy() {

  }
}
