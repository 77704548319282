export const kerberosConfig = {
  environment: window["env"]["environment"] || "production",
  privateEdition: (window["env"]["privateEdition"] === "true") ? true : false,
  multiTenant: (window["env"]["multiTenant"] === "true") ? true : false,
  tenantBaseDomain: window["env"]["tenantBaseDomain"] || "",
  title: window["env"]["title"] || "",
  logo: window["env"]["logo"] || "",
  loginDescription: window["env"]["loginDescription"] || "",
  loginCopyright: window["env"]["loginCopyright"] || "",
  mainColor:  window["env"]["mainColor"] || "",
  apiUrl: window["env"]["apiUrl"] || "",
  newApiUrl: window["env"]["newApiUrl"] || "",
  turnServer: window["env"]["turnServer"] || "",
  turnUsername: window["env"]["turnUsername"] || "",
  turnPassword: window["env"]["turnPassword"] || "",
  mqttProtocol: window["env"]["mqttProtocol"] || "",
  mqttLegacyServer: window["env"]["mqttLegacyServer"] || "",
  mqttLegacyPort: window["env"]["mqttLegacyPort"] || 443,
  mqttServer: window["env"]["mqttServer"] || "",
  mqttPort: window["env"]["mqttPort"] || 443,
  mqttUsername: window["env"]["mqttUsername"] || "",
  mqttPassword: window["env"]["mqttPassword"] || "",
  ssoDomains: window["env"]["ssoDomains"] || "",
  openaiEnabled: (window["env"]["openaiEnabled"] === "true") ? true : false,
  dashboardTitle: window["env"]["dashboardTitle"] || "",
  dashboardSubTitle: window["env"]["dashboardSubTitle"] || "",
  latestEventsTitle: window["env"]["latestEventsTitle"] || "",
  latestEventsSubTitle: window["env"]["latestEventsSubTitle"] || "",
  livestreamTitle: window["env"]["livestreamTitle"] || "",
  livestreamSubTitle: window["env"]["livestreamSubTitle"] || "",
  mediaTitle: window["env"]["mediaTitle"] || "",
  mediaSubTitle: window["env"]["mediaSubTitle"] || "",
  dayTitle: window["env"]["dayTitle"] || "",
  daySubTitle: window["env"]["daySubTitle"] || "",
  cpuUsageDescription: window["env"]["cpuUsageDescription"] || "",
  framesPerSecondDescription: window["env"]["framesPerSecondDescription"] || "",
  mlaUtilizationDescription: window["env"]["mlaUtilizationDescription"] || "",
  objectsDetectedDescription: window["env"]["objectsDetectedDescription"] || "",
  hideAddAgent: (window["env"]["hideAddAgent"] === "true") ? true : false,
  navigationLinkTitle1: window["env"]["navigationLinkTitle1"] || "",
  navigationLinkUrl1: window["env"]["navigationLinkUrl1"] || "",
  navigationLinkTitle2: window["env"]["navigationLinkTitle2"] || "",
  navigationLinkUrl2: window["env"]["navigationLinkUrl2"] || "",
  navigationLinkTitle3: window["env"]["navigationLinkTitle3"] || "",
  navigationLinkUrl3: window["env"]["navigationLinkUrl3"] || "",
  navigationLinkTitle4: window["env"]["navigationLinkTitle4"] || "",
  navigationLinkUrl4: window["env"]["navigationLinkUrl4"] || "",
  navigationLinkTitle5: window["env"]["navigationLinkTitle5"] || "",
  navigationLinkUrl5: window["env"]["navigationLinkUrl5"] || "",
};
